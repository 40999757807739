import { Country } from '@/domain/address/types';
import { EShopType } from '@/domain/shop/types';
import { UserPrivate } from '@/domain/user/types';

export enum EUserType {
  Buyer = 'buyer',
  Seller = 'seller',
}

export enum EProfileType {
  Individual = 'individual',
  ProfessionalBuyer = 'professional-buyer',
  ProfessionalSeller = 'professional-seller',
}

export enum EJobType {
  Architect = 'architect',
  Company = 'company',
  Hotel = 'hotel',
  Liberal = 'liberal',
  Medical = 'medical',
  Other = 'other',
  RealEstate = 'real_estate',
  Restaurant = 'restaurant',
  Shop = 'shop',
}

export interface AuthenticatedUser extends UserPrivate {
  token: string;
}

export interface LoginPayload {
  password: string;
  username: string;
}

export interface LoginApplePayload {
  email?: string;
  firstName?: string;
  lastName?: string;
  token: string;
}

export interface RegisterPayload {
  activity?: `${EUserType}` | null;
  appleId?: string;
  appleToken?: string;
  businessId?: string;
  country?: Country['code'];
  email: string;
  firstName: string;
  googleId?: string;
  googleToken?: string;
  job?: `${EJobType}` | null;
  lastName: string;
  newsletter?: boolean;
  password: string;
  type?: `${EShopType}`;
}

export interface RegisterProfile {
  payload: Pick<RegisterPayload, 'activity' | 'type'>;
  type: `${EProfileType}`;
}

export type ShopUpdatePayload = Pick<RegisterPayload, 'activity' | 'businessId' | 'job' | 'type'>;
