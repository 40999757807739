
import Vue from 'vue';

import IcLoader from '@/assets/svg/ic_loader.svg?inline';

// NOTE: this placeholder is a bit specific : it either holds the place (No shit, Sherlock 🔍) for the SDK being fetched
// or displays an overlay (with a loader).
export default Vue.extend({
  name: 'PlaceholderButtonConnect',
  components: {
    IcLoader,
  },
  props: {
    isFetchingSDK: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
});
