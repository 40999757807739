
import Vue, { PropType } from 'vue';

import IcEyeClosed from '@/assets/svg/ic_eye_closed.svg?inline';
import IcEyeOpen from '@/assets/svg/ic_eye_open.svg?inline';
import { EInputType, InputType } from '@/domain/core/InputType.enum';

export default Vue.extend({
  name: 'InputPassword',
  components: {
    IcEyeClosed,
    IcEyeOpen,
  },
  props: {
    autocomplete: {
      type: String,
      default: 'new-password',
    },
    cssSpacingClasses: {
      type: String,
      default: '',
    },
    dataTestId: {
      type: String,
      default: 'input-password',
    },
    error: {
      type: String as PropType<string | null>,
      default: null,
    },
    inputId: {
      type: String,
      default: '',
    },
    inputName: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isPasswordVisible: false,
    };
  },
  computed: {
    modelValue: {
      get(): string {
        return this.value;
      },
      set(value: string): void {
        this.$emit('update:value', value);
      },
    },
    inputType(): InputType {
      return this.isPasswordVisible ? EInputType.Text : EInputType.Password;
    },
  },
  methods: {
    togglePasswordVisibility(): void {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
  },
});
