
import Vue from 'vue';

import { GoogleApiSignInResponse } from '@/infrastructure/auth/types';
import { _insertScript } from '@/utilities/misc/insertScript';

export default Vue.extend({
  name: 'ButtonConnectGoogle',
  data() {
    return {
      isFetchingSDK: false,
      isLoading: false,
    };
  },
  mounted() {
    this.loadGoogleSDK();
  },
  methods: {
    async handleCredentialResponse(response: GoogleApiSignInResponse): Promise<void> {
      try {
        this.setLoadingState(true);

        const { isNewUser } = await this.$services.authService.loginWithGoogle(response.credential);
        const eventName = isNewUser ? 'register' : 'login';

        this.$emit(eventName);
      } catch (err) {
        this.$emit('error', err);
        this.$errorMonitor.report(err, 'fatal');
      } finally {
        this.setLoadingState(false);
      }
    },
    async loadGoogleSDK(): Promise<void> {
      try {
        this.setSDKFetchingState(true);

        await _insertScript({
          id: 'google-jssdk',
          src: 'https://accounts.google.com/gsi/client',
        });

        this.setSDKFetchingState(false);

        this.initGoogleSdk();
        this.renderGoogleButton();
      } catch (err) {
        this.$errorMonitor.report(err, 'fatal');
      } finally {
        this.setSDKFetchingState(false);
      }
    },
    initGoogleSdk(): void {
      window.google.accounts.id.initialize({
        client_id: this.$config.google.app.id,
        context: 'signin',
        ux_mode: 'popup',
        callback: this.handleCredentialResponse,
      });
    },
    async renderGoogleButton(): Promise<void> {
      // NOTE: needed to wait for the node to be rendered propery following the `isFetchingSDK` update. Otherwise the $ref would be undefined.
      await this.$nextTick();
      const buttonWrapper = (this.$refs.googleButton as HTMLElement);

      window.google.accounts.id.renderButton(buttonWrapper, {
        type: 'icon',
        shape: 'circle',
        size: 'large',
      });
    },
    setLoadingState(state: boolean): void {
      this.isLoading = state;
    },
    setSDKFetchingState(state: boolean): void {
      this.isFetchingSDK = state;
    },
  },
});
