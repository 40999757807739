var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative w-full",class:[_vm.cssSpacingClasses]},[((_vm.inputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelValue),expression:"modelValue"}],ref:"inputPassword",staticClass:"w-full p-3 lg:p-3.5 pr-10 lg:pr-10 font-sans text-sm rounded-none appearance-none ring-1 ring-inset text-ellipsis whitespace-nowrap focus:outline-none placeholder:text-gray-darker placeholder:text-sm",class:{
      'text-gray-darker bg-gray cursor-not-allowed': _vm.isDisabled,
      'ring-gray focus:ring-gray-darker': !_vm.error,
      'ring-red focus:ring-red': _vm.error,
    },attrs:{"id":_vm.inputId,"autocomplete":_vm.autocomplete,"data-testid":_vm.dataTestId,"disabled":_vm.isDisabled,"name":_vm.inputName,"placeholder":_vm.placeholder,"required":_vm.isRequired,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.modelValue)?_vm._i(_vm.modelValue,null)>-1:(_vm.modelValue)},on:{"change":function($event){var $$a=_vm.modelValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.modelValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.modelValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.modelValue=$$c}}}}):((_vm.inputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelValue),expression:"modelValue"}],ref:"inputPassword",staticClass:"w-full p-3 lg:p-3.5 pr-10 lg:pr-10 font-sans text-sm rounded-none appearance-none ring-1 ring-inset text-ellipsis whitespace-nowrap focus:outline-none placeholder:text-gray-darker placeholder:text-sm",class:{
      'text-gray-darker bg-gray cursor-not-allowed': _vm.isDisabled,
      'ring-gray focus:ring-gray-darker': !_vm.error,
      'ring-red focus:ring-red': _vm.error,
    },attrs:{"id":_vm.inputId,"autocomplete":_vm.autocomplete,"data-testid":_vm.dataTestId,"disabled":_vm.isDisabled,"name":_vm.inputName,"placeholder":_vm.placeholder,"required":_vm.isRequired,"type":"radio"},domProps:{"checked":_vm._q(_vm.modelValue,null)},on:{"change":function($event){_vm.modelValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelValue),expression:"modelValue"}],ref:"inputPassword",staticClass:"w-full p-3 lg:p-3.5 pr-10 lg:pr-10 font-sans text-sm rounded-none appearance-none ring-1 ring-inset text-ellipsis whitespace-nowrap focus:outline-none placeholder:text-gray-darker placeholder:text-sm",class:{
      'text-gray-darker bg-gray cursor-not-allowed': _vm.isDisabled,
      'ring-gray focus:ring-gray-darker': !_vm.error,
      'ring-red focus:ring-red': _vm.error,
    },attrs:{"id":_vm.inputId,"autocomplete":_vm.autocomplete,"data-testid":_vm.dataTestId,"disabled":_vm.isDisabled,"name":_vm.inputName,"placeholder":_vm.placeholder,"required":_vm.isRequired,"type":_vm.inputType},domProps:{"value":(_vm.modelValue)},on:{"input":function($event){if($event.target.composing)return;_vm.modelValue=$event.target.value}}}),_vm._v(" "),_c('BaseButton',{staticClass:"absolute top-0 right-0 z-10 flex items-center justify-center w-auto h-12 px-4 focus:ring-black",attrs:{"aria-checked":_vm.isPasswordVisible,"is-unstyled":"","role":"switch","title":_vm.$t('form.common.input.password.show')},on:{"click":_vm.togglePasswordVisibility}},[(_vm.isPasswordVisible)?_c('IcEyeOpen',{staticClass:"size-4",attrs:{"aria-hidden":"true"}}):_c('IcEyeClosed',{staticClass:"size-4",attrs:{"aria-hidden":"true"}})],1),_vm._v(" "),_c('TransitionCollapse',[(_vm.error)?_c('p',{staticClass:"text-xs text-red",attrs:{"data-testid":`${_vm.dataTestId}-error`}},[_vm._v("\n      "+_vm._s(_vm.error)+"\n    ")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }