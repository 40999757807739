
import Vue from 'vue';

export default Vue.extend({
  name: 'ButtonConnectGeneric',
  props: {
    isFetchingSDK: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(): void {
      this.$emit('click');
    },
  },
});
