
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';

import { EUserType, RegisterPayload } from '@/domain/auth/types';
import { ELabelledById } from '@/domain/core/a11y/LabelledById.enum';
import { ESidebarComponentId } from '@/domain/core/a11y/SidebarComponentId.enum';
import { ECookie } from '@/domain/core/http/Cookie.enum';
import { EHttpStatusCode } from '@/domain/core/http/HttpStatusCode.enum';
import { EMaxAge } from '@/domain/core/http/MaxAge.enum';
import { EShopType } from '@/domain/shop/types';
import { HttpResponse } from '@/infrastructure/core/http/HttpResponse.interface';
import { EGtmAuthenticationMethod, EGtmEventUser, ENewsletterSubmitLocation } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { _isEmailValid } from '@/utilities/email/isEmailValid';

type IdentityProvider = `${EGtmAuthenticationMethod}`;

const newsletterModalCookieGenericValue = 1;
const documentPercentageScrollThreshold = 40;

/**
 * This modal is a bit special.
 * To avoid poluting the parent with this specific logic, it's been encapsulated in the modal.
 * It always mounts, embarqs BaseModal directly and depends on a local flag (isModalOpen).
 * Its "open" state depends on a scroll listener and does not emit any 'close' event.
 */
export default Vue.extend({
  name: 'ModalNewsletter',
  data() {
    return {
      EGtmAuthenticationMethod,
      ELabelledById,
      errorMessage: null as TranslateResult | null,
      hasSentTooManyRequests: false,
      isLoading: false,
      isModalOpen: false,
      isUserAlreadyRegistered: false,
      form: { // Set to Individual Buyer by default and opt it in to the newsletter.
        activity: EUserType.Buyer,
        email: '',
        firstName: '',
        lastName: '',
        newsletter: true,
        password: '',
        type: EShopType.Individual,
      } as RegisterPayload,
      isSuccessStep: false,
      isRegisterStep: false,
    };
  },
  computed: {
    emailErrorLabel(): TranslateResult | null {
      return this.hasSentTooManyRequests ? this.$t('form.common.errors.tooManyRequests') : null;
    },
  },
  watch: {
    isModalOpen(openState: boolean): void {
      // NOTE: Once at success step, we should reload the page to properly fetch every user's informations.
      if (!openState && this.isSuccessStep) {
        this.reloadPage();
      }
    },
  },
  mounted(): void {
    if (this.$cookies.get(ECookie.NewsletterModalDismissed) === undefined) {
      window.addEventListener('scroll', this.openModalAtScrollThreshold);
    }
  },
  methods: {
    handleOneClickError(): void {
      this.errorMessage = this.$t('form.common.errors.generic');
    },
    handleModalClosing(): void {
      this.isModalOpen = false;

      this.setCookieAndRemoveEventListener();
    },
    handleTracking({ isNewUser, method }: { isNewUser?: boolean; method: IdentityProvider }): void {
      this.$gtm.push({
        event: isNewUser ? EGtmEventUser.Register : EGtmEventUser.Login, // WARNING: not event set as of today.
        method,
      });
    },
    async handleLoginSuccess(method: IdentityProvider): Promise<void> {
      this.errorMessage = null;
      this.handleTracking({ method });

      try {
        await this.$accessor.user.fetchUser(); // Needed to perform authenticated API calls.
        const userSubscriptions = await this.$repositories.user.getSubscriptions(this.$accessor.user.id); // Check user's subscriptions.

        // Force newsletter opt in.
        // NOTE: at this point, user is most likely to be non-elligible for the discount.
        if (!userSubscriptions?.isSubscribedNewsletter) {
          await this.subscribeUserToNewsletter(this.$accessor.user.id);

          this.trackNewsletterOptin();
        }

        this.isSuccessStep = true; // Go to success message.
      } catch (err) {
        this.$errorMonitor.report(err, 'fatal');
      }
    },
    async handleRegisterSuccess(method: IdentityProvider): Promise<void> {
      this.errorMessage = null;
      this.handleTracking({ isNewUser: true, method });

      try {
        await this.$accessor.user.fetchUser(); // Needed to perform authenticated API calls.
        await this.subscribeUserToNewsletter(this.$accessor.user.id); // Update newly registered user's opt in option.

        this.trackNewsletterOptin();
        this.isSuccessStep = true;
      } catch (err) {
        this.$errorMonitor.report(err, 'fatal');
      }
    },
    async handleSubmit(): Promise<void> {
      if (!_isEmailValid(this.form.email)) {
        this.errorMessage = this.$t('form.subscription.incorrectEmailMessage');

        return;
      }

      this.errorMessage = null;
      this.hasSentTooManyRequests = false;
      this.isLoading = true;
      this.isUserAlreadyRegistered = false;

      try {
        // [1] Verify user's existence
        await this.$repositories.user.getPublicDetailsByEmail(this.form.email);

        this.isUserAlreadyRegistered = true;
      } catch (err) {
        const { statusCode } = err as HttpResponse;

        if (statusCode === EHttpStatusCode.NotFound) {
          // [1 bis] User DOES NOT exists but everything OK ?
          // Subscribe to newsletter (legacy) to at least make sure it's properly subscribed.
          const successfulSubscriptionStatus = 'subscribed';

          try {
            const recaptchaToken = await this.$recaptcha.execute('newsletter');
            const legacyUser = await this.$repositories.auth.subscribeNewsletter(this.form.email, recaptchaToken);

            if (legacyUser?.status !== successfulSubscriptionStatus) {
              throw new Error('Unsuccessful subscription');
            }

            this.trackNewsletterOptin();

            // [2] Go to register step.
            this.isRegisterStep = true;
          } catch (err_) {
            this.$errorMonitor.report(err_, 'fatal');
          }
        } else if (statusCode === EHttpStatusCode.TooManyRequests) {
          this.hasSentTooManyRequests = true;
        } else {
          this.$errorMonitor.report(err, 'fatal');
        }
      } finally {
        this.isLoading = false;
      }
    },
    hasReachedScrollThreshold(): boolean {
      const scrollThreshold = (documentPercentageScrollThreshold / 100) * document.body.scrollHeight;

      return window.scrollY > scrollThreshold;
    },
    openModal(): void {
      this.isModalOpen = true;
    },
    openModalAtScrollThreshold(): void {
      if (this.hasReachedScrollThreshold()) {
        this.openModal();
      }
    },
    async openFormLoginSidebar(targetPath: PointerEvent | string | null): Promise<void> {
      this.handleModalClosing(); // Close modal first. Its closing will trigger the closeDialog store action. NOTE: closing modal at success step will reload the page.

      await this.$nextTick();

      this.$accessor.ui.openSidebar({
        currentComponent: {
          // @ts-ignore
          component: () => import('@/components/organisms/form/FormLogin.vue'),
          id: ESidebarComponentId.FormLogin,
          props: {
            currentEmail: this.form.email, // Prefill email's input if possible.
            targetPath: typeof targetPath === 'string' ? targetPath : null,
          },
        },
      });
    },
    reloadPage(): void {
      window.location.reload();
    },
    async handleRegister(): Promise<void> {
      this.errorMessage = null;
      this.isLoading = true;

      try {
        const user = await this.$services.authService.register(this.form);

        this.$accessor.user.SET_USER(user); // NOTE: set user but let 'authenticated' set to false.

        // this.handleTracking();
        this.isSuccessStep = true;
      } catch (err) {
        this.errorMessage = this.$t('form.common.errors.generic');
        this.$errorMonitor.report(err, 'fatal');
      } finally {
        this.isLoading = false;
      }
    },
    setCookieAndRemoveEventListener() {
      this.$cookies.set(ECookie.NewsletterModalDismissed, newsletterModalCookieGenericValue, {
        maxAge: EMaxAge.OneMonth, // Its value doesn't matter.
        path: '/',
        sameSite: 'lax',
      });

      window.removeEventListener('scroll', this.openModalAtScrollThreshold);
    },
    async subscribeUserToNewsletter(userId: string): Promise<void> {
      // [2] User exists ? Make sure it's properly opted in by forcing the opt in (lol).
      await this.$repositories.user.updateSubscriptions(userId || '', {
        isSubscribedNewsletter: true,
        isSubscribedSuggestedProducts: true,
        isSubscribedTexts: true,
        isSubscribedWishlistNotifications: true,
      });
    },
    trackNewsletterOptin(): void {
      this.$gtm.push({
        event: EGtmEventUser.NewsletterOptin,
        location: ENewsletterSubmitLocation.Popin,
      });
    },
  },
});
